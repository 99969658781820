/* Add this CSS to your stylesheet or style tag */
.tab-with-dashes::before,
.tab-with-dashes::after {
  content: '';
  display: block;
  position: absolute;
  width: 100%;
  height: 0;
  border-top: 1px dashed black; /* Adjust the dashed line style as needed */
  top: -5px; /* Adjust the distance of the dashed line from the tab as needed */
}

.tab-with-dashes::after {
  bottom: -5px; /* Adjust the distance of the dashed line from the tab as needed */
}


