
@keyframes animatedv1 {
    0% {
        left: -1000%;
    }
    100% {
        left: -2%; /* Move the object to the right */
    }
}

@keyframes animatedv2 {
    0% {
        left: -1000%;
    }
    100% {
        left: -28%; /* Move the object to the right */
    }
}

@keyframes animatedv3 {
    0% {
        left: -1000%;
    }
    100% {
        left: -64%; /* Move the object to the right */
    }
}

@keyframes animatedv4 {
    0% {
        left: -1000%;
    }
    100% {
        left: -79%; /* Move the object to the right */
    }
}

@keyframes animatedv5 {
    0% {
        left: -1000%;
    }
    100% {
        left: -112%; /* Move the object to the right */
    }
}

.animatedv1 {
    animation: animatedv1 10s ease 1 forwards;
}

.animatedv2 {
    animation: animatedv2 10s ease 1 forwards;
}

.animatedv3 {
    animation: animatedv3 10s ease 1 forwards;
}

.animatedv4 {
    animation: animatedv4 10s ease 1 forwards;
}

.animatedv5 {
    animation: animatedv5 10s ease 1 forwards;
}

@keyframes mobanimatedv1 {
    0% {
        left: -1600%;
    }
    100% {
        left: -27%; /* Move the object to the right */
    }
}

@keyframes mobanimatedv2 {
    0% {
        left: -1600%;
    }
    100% {
        left: -79%; /* Move the object to the right */
    }
}

@keyframes mobanimatedv3 {
    0% {
        left: -1600%;
    }
    100% {
        left: -110%; /* Move the object to the right */
    }
}

@keyframes mobanimatedv4 {
    0% {
        left: -1600%;
    }
    100% {
        left: -142%; /* Move the object to the right */
    }
}

@keyframes mobanimatedv5 {
    0% {
        left: -1600%;
    }
    100% {
        left: -190%; /* Move the object to the right */
    }
}

.mobanimatedv1 {
    animation: mobanimatedv1 10s ease 1 forwards;
}

.mobanimatedv2 {
    animation: mobanimatedv2 10s ease 1 forwards;
}

.mobanimatedv3 {
    animation: mobanimatedv3 10s ease 1 forwards;
}

.mobanimatedv4 {
    animation: mobanimatedv4 10s ease 1 forwards;
}

.mobanimatedv5 {
    animation: mobanimatedv5 10s ease 1 forwards;
}

  
  .indicatorLine {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 50%; /* Position the line in the middle horizontally */
    transform: translateX(-50%); /* Center the line horizontally */
    width: 2px; /* Adjust the width of the line */
    background-color: yellow; /* Color of the line */
    z-index: 2;
  }
  