.iconSVG {
    width: 35px;
    height: 35px;
    color: white;
    fill: white;
  }

  a:link { color: lightskyblue; }
a:visited { color: lightskyblue; }
a:hover { color: #ffffff; }
a:active { color: #ff4040; text-decoration:none; font-weight:normal; }


  .iconSVGMob {
    width: 2.188rem;
    height: 2.188rem;
    padding: 2px;
    color: white;
    fill: white;
  }

.Mui-selected {
  background-color: #bb171a;
  border-color: #bb171a;
  color: white;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid #bb171a;
    border-top-color: transparent;
    border-right-color: transparent;
    border-bottom-color: transparent;
    border-left-color: transparent;
  padding: .375rem .75rem;
  font-size: 1rem;
  border-radius: .25rem;
  margin-left: '-1px';
  transition: color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out;
}

.container {
  position: relative;
  width: 300px;
  height: 200px;
  border: 1px solid #ccc;
  overflow: hidden;
}

.ribbon {
  position: absolute;
  top: 22px;
  left: -52px;
  background-color: #bb171a;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 48px;
  transform: rotate(-45deg);
  clip-path: polygon(27% 0, 71% 0, 86% 100%, 12% 100%);
  /* clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); */

  /* transform-origin: 100% 0; */
  z-index: 3;
}

.greenRibbon {
  background-color: rgb(43, 171, 43); 
  position: absolute;
  top: 22px;
  left: -52px;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 48px;
  transform: rotate(-45deg);
  clip-path: polygon(30% 0, 72% 0, 88% 100%, 14% 100%);
  /* clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); */

  /* transform-origin: 100% 0; */
  z-index: 3;
}

.greenArmoryRibbon {
  background-color: limegreen; 
  position: absolute;
  top: 18px;
  left: -40px;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 48px;
  transform: rotate(-45deg);
  clip-path: polygon(25% 0, 76% 0, 94% 100%, 4% 100%);
  border: 2px solid rgba(255, 255, 255, 0.12);
  /* clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); */

  /* transform-origin: 100% 0; */
  z-index: 3;
}

.greenStoreRibbon {
  background-color: limegreen; 
  position: absolute;
  top: 26px;
  left: -54px;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 48px;
  transform: rotate(-45deg);
  clip-path: polygon(29% 0, 73% 0, 88% 100%, 14% 100%);
  /* clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); */

  /* transform-origin: 100% 0; */
  z-index: 3;
}

.orangeRibbon {
  background-color: rgb(238, 70, 9); 
  position: absolute;
  top: 21px;
  left: -50px;
  color: #fff;
  font-size: 0.8rem;
  padding: 4px 48px;
  transform: rotate(-45deg);
  clip-path: polygon(31% 0, 72% 0, 89% 100%, 14% 100%);
  /* clip-path: polygon(20% 0, 80% 0, 100% 100%, 0 100%); */

  /* transform-origin: 100% 0; */
  z-index: 3;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border: 0;
}
