.corner-outlines {
    box-sizing:border-box;
    /* margin:5px; */
    display:inline-flex;
    font-size:30px;
    justify-content:center;
    align-items:center;
    text-align:center;
    border: var(--b) solid #0000; /* space for the border */
}

.corner-outlines:hover {
    --b: 5px;   /* thickness of the border */
    --c: rgba(255, 255, 255, 0.3);   /* color of the border */
    --w: 20px;  /* width of border */
    
  
    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
      conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
      conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
      conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
    
    /*Irrelevant code*/  

  }
  
  .corner-outlines-mob {
    box-sizing:border-box;
    /* margin:5px; */
    display:inline-flex;
    font-size:30px;
    justify-content:center;
    align-items:center;
    text-align:center;
    border: var(--b) solid #0000; /* space for the border */
}

.corner-outlines-mob:hover {
    --b: 3px;   /* thickness of the border */
    --c: rgba(255, 255, 255, 0.3);   /* color of the border */
    --w: 10px;  /* width of border */
    
  
    border: var(--b) solid #0000; /* space for the border */
    --_g: #0000 90deg,var(--c) 0;
    --_p: var(--w) var(--w) border-box no-repeat;
    background:
      conic-gradient(from 90deg  at top    var(--b) left  var(--b),var(--_g)) 0    0    / var(--_p),
      conic-gradient(from 180deg at top    var(--b) right var(--b),var(--_g)) 100% 0    / var(--_p),
      conic-gradient(from 0deg   at bottom var(--b) left  var(--b),var(--_g)) 0    100% / var(--_p),
      conic-gradient(from -90deg at bottom var(--b) right var(--b),var(--_g)) 100% 100% / var(--_p);
    
    /*Irrelevant code*/  

  }
  